import React, { useState } from 'react';
import axios from 'axios';

import logo from '../assets/images/blacknoback.png';
import '../formStyle.css';

function BrandCreate(props) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(''); // Add state for error message
  // const [brandvisibility, setBrandVisibility] = useState('');
  const [brandname, setBrandName] = useState('');

  // const navigate = useNavigate();

//   const { state } = useLocation();
//   const { phoneNumber, name, gender, dob, selectedState, interests, email } = state;
 

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff)$/i.test(filename);
  };

  const handleMediaChangeBrand = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file.name)) {
      setError(''); // Clear any previous error messages
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError('Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff).');
      setImage(null); // Clear the image if the file type is invalid
    }
  };
// this function will be called when a radio button is checked
  // const handleAssetVisibilityChange = (e) => {
  //   setBrandVisibility(e.target.value);
  // }
  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      BrandName: brandname,
      // Visibility: brandvisibility,
      brandImage: image,
    };
    console.log(postData);
    axios
      .post('https://ambassco.feeltiptop.com/api/addBrand', postData,{
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        console.log('Post response:', response.data);
        alert(response.data)
        // setPostStatus({ success: true, error: '' });
      })
      .catch((error) => {
        console.error('Error creating post:', error);
        alert(error)
        // setPostStatus({ success: false, error: 'Error creating post.' }); // here
      });
    // navigate('/payment', { state: { phoneNumber, name, gender, dob, selectedState, interests, email, profilePicture: image } });

  };
  const handleReset = () => {
    setImage(null);
    setError('');
    setBrandName('');
  };

  return (
    <div>
    <header className="AssetForm-header">
        <img src={logo} className="Ambassco-logo" alt="logo" />
        <div className="formName">Brand Generation Form</div>
    </header>
    {/* <div class="formName">Brand Generation Form</div> */}
    <form action="" method="POST" encType="multipart/form-data">
        <div class="formElements">
            <label for="brandName">Brand Name:</label>
            <input type="text" id="brandNameSelect" placeholder="Enter Brand" 
            value={brandname}
            onChange={(e) => setBrandName(e.target.value)}
            />

            <label for="brandPP">
                {image ? <img src={image} alt="Media File" className="mediaInput" /> : 'Brand Profile Picture:'}
            </label>
            <input
                 type="file"
                 id="fileInput"
                 accept="image/jpg, image/jpeg, image/png, image/gif, image/tiff" // Specify accepted file types (Add HEIC in future)
                 onChange={handleMediaChangeBrand}
               />
            <br/>
            <input class="reset" type="reset"
              onClick={handleReset}
            />
            <input class="submit" type="submit"
                onClick={handleSubmit}
            
            />
        </div>
    </form>
    </div>
  );
}

export default BrandCreate;
