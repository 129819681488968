// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssetForm {
    background-color: white;
}

.Ambassco-logo {
    height: 10vw;
}

.formTitle {
    margin-top: 2vw;
    font-size: 5vw;
    color: black;
}

.AssetForm-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

form {
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-right: 10vw;
}

input {
    font-family: monospace;
}

label {
    margin-bottom: .2vw;
    align-self: flex-start;
}

.mediaInput {
    max-height: 20vw;
}`, "",{"version":3,"sources":["webpack://./src/assetFormStyle.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,YAAY;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".AssetForm {\n    background-color: white;\n}\n\n.Ambassco-logo {\n    height: 10vw;\n}\n\n.formTitle {\n    margin-top: 2vw;\n    font-size: 5vw;\n    color: black;\n}\n\n.AssetForm-header {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: center;\n}\n\nform {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10vw;\n    margin-right: 10vw;\n}\n\ninput {\n    font-family: monospace;\n}\n\nlabel {\n    margin-bottom: .2vw;\n    align-self: flex-start;\n}\n\n.mediaInput {\n    max-height: 20vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
