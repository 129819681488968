import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function AddEmail() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const { state } = useLocation();
  const {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests} = state;

  const handleBackArrow = () => {
    navigate('/SelectInterests', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState}});
  };

  const isValidEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = () => {
    navigate('/uploadprofilepicture', { state: { phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email: email} });
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className='title-verification'>What's your email?</h2>

      <input
        type="email"
        className="input-field"
        value={email}
        placeholder="johnsmith@example.com" 
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
        <button
          style={{marginTop:'200px'}}
          className={`button ${isValidEmail(email) ? 'red-background' : 'grey-background'}`}
          disabled={!isValidEmail(email)}
          onClick={handleSubmit}
        >
          Next
        </button>
    </div>
  );
}

export default AddEmail;
