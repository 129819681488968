import { Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

export default function AmbassadorGraph(props) {
    const chartLabels = Object.keys(props.chartValues)
    const values = chartLabels.map((label) => props.chartValues[label])
    const statContainerStyle = {
        borderRadius: '8px',
        border: '1px solid #CBCBCB',
        background: '#FFF',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
        width: "665.5px",
        height: "394px",
        flexShrink: "0",
    }

    const graphTitleStyle =  {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        marginLeft: '31px',
        marginTop: '22px'
    }

    const graphBodyStyle = {
        marginLeft: '26px',
        height: '270px',
        position: 'relative'        
    }
    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        }
    }
    const pieOptions = {
        responsive: true,
        maintainAspectRation: false,
        aspectRatio: 2,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        plugins: {
            legend: {
                position: 'left',
                align: 'start'
            }
        }
    }

    const barData = {
        labels: chartLabels,
        datasets: [
          {
            data: values,
            backgroundColor: props.color,
          },
        ],
    };

    const pieData = {
        labels: chartLabels,
        datasets: [
          {
            label: '# of Ambassadors',
            data: values,
            backgroundColor: [
              '#3B7EDB',
              '#CEE4E7',
              '#DB4225',
              '#EFC582',
              '#F6DADA',
              '#BFC0DF',
              '#77d1fe',
              '#575d62'
            ],
          },
        ],
      }

    const graph = props.isBar 
        ? <Bar options={barOptions} data={barData}></Bar>
        : <Doughnut options={pieOptions} data={pieData} width="600px"></Doughnut>
      
    return (
        <div style={statContainerStyle}>
            <p style={graphTitleStyle}>{props.name}</p>
            <div style={graphBodyStyle}>
                {graph}
            </div>
        </div>
    )
}