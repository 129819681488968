import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function EnterName() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber} = state;

  const handleBackArrow = () => {
    navigate('/signup');
  };

  const handleSubmit = () => {
    navigate('/gender', { state: {phoneNumber, firstName: firstName, lastName: lastName} });
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
        <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow} />
      </div>
      <h2 className="title-signup">What's your name?</h2>

      <div>
        <input
            type="text" 
            placeholder="First Name"
            className="input-fields"
            value={firstName}
            onChange={(e) => {
            setFirstName(e.target.value); 
            }}
        />
      </div>
      <div>
        <input
            type="text" 
            placeholder="Last Name"
            className="input-fields"
            value={lastName}
            onChange={(e) => {
            setLastName(e.target.value); 
            }}
        />
      </div>

        <button
          style={{marginTop:'200px'}}
          className={`button ${lastName.length > 0 && firstName.length > 0 ? 'red-background' : 'grey-background'}`}
          disabled={firstName.length === 0 || lastName.length === 0}
          onClick={handleSubmit}
        >
          Next
        </button>
    </div>
  );
}

export default EnterName;
