import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function AddEmail() {
  const [paymentemail, setEmail] = useState('');
  const navigate = useNavigate();

  const { state } = useLocation();
  const {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType} = state;
  const handleBackArrow = () => {
    navigate('/payment', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image}});
  };

  const isValidEmail = (paymentemail) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(paymentemail);
  };

  const handleSubmit = () => {
    navigate('/TermsAndConditions', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType, paymentInfo: paymentemail}} );
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className='title-verification'>Email connected to your Paypal account:</h2>

      <input
        type="email"
        className="input-field"
        value={paymentemail}
        placeholder="johnsmith@example.com" 
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
        <button
          style={{marginTop: '200px'}}
          className={`button ${isValidEmail(paymentemail) ? 'red-background' : 'grey-background'}`}
          disabled={!isValidEmail(paymentemail)}
          onClick={handleSubmit}
        >
          Submit
        </button>
    </div>
  );
}

export default AddEmail;
