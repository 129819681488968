import CommentBox from "./CommentBox"

export default function CommentContainer(props) {
    const commentContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxHeight: '694px',
        overflow: 'auto',
        width: '1361px',
        borderRadius: '8px',
        border: '1px solid #CBCBCB',
        background: '#FFF',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)'
    }

    const commentMap = (comment) =>
    <CommentBox {...comment}></CommentBox>

    return(
        props.comments.length ? (
        <div style={commentContainerStyle}>
            {props.comments.map(commentMap)}
        </div>
        )
        : (<div style={{fontSize: '20px'
        }}>No Comment</div>)
    )
}