import React, { useState, useEffect } from 'react';
import axios from "axios";
import '../App.css';

export default function FeedCommentBox(props) {
    const [currentComment, setCurrentComment] = useState('')

    useEffect(() => {
      setCurrentComment('');
    }, [props.activeAssetId]);

    const commentOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
        display: props.isCommentModalOpen ? 'block' : 'none', // Only display when isCommentModalOpen is true
      };

    const commentModalStyle = {
        width: '90%',
        height: '40%',
        minHeight: '350px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '16px',
        zIndex: 1000,
        display: props.isCommentModalOpen ? 'block': 'none', // Only display when isCommentModalOpen is true
        fontFamily: "Acumin-RPro",
        fontSize: '16px'
      };

    const commentBoxStyle = {
        width: '100%',
        height: '75%',
        margin: '0 0 10px 0',
        background: '#E5E5E5',
        boxSizing: 'border-box',
    }

    const commentTextBoxStyle = {
        width: '90%',
        height: '90%',
        border: "none",
        resize: "none",
        background: '#E5E5E5',
        outline: 'none',
    }

    const submitComment = async () => {
        if (props.activeAssetId && currentComment) {
          try {
      
            const response = await axios.post('https://ambassco.feeltiptop.com/api/comment', {
              assetID: props.activeAssetId,
              userID: props.userID,
              feedID: props.feedID, 
              comment: currentComment,
            }, {        
              headers: { 'Authorization': 'Bearer ' + props.token}
            });
            response.data.access_token && props.setToken(response.data.access_token)
            if (response.data.success) {
              console.log('Comment submitted successfully');
              props.onCommentSubmit();
            } else {
              console.error('Server responded with failure');
            }
          } catch (error) {
            console.error('Error submitting comment:', error);
          }
        }
        setCurrentComment('')
        props.setIsCommentModalOpen(false);
        props.setActiveAssetId(null);
      };

    return (
        <>
          <div style={commentOverlayStyle} onClick={() => props.setIsCommentModalOpen(false)} />
          <div style={commentModalStyle}>
            <div style={commentBoxStyle}>
              <textarea
                style={commentTextBoxStyle}
                value={currentComment}
                onChange={(e) => setCurrentComment(e.target.value)}
                placeholder="Enter comment here..."
              />
            </div>
            <button
              style = {{marginTop: "10px"}}
              className={`button ${!currentComment? 'grey-background' : 'red-background'}`}
              disabled={!currentComment}
              onClick={submitComment}
            >
            Submit
            </button>
          </div>
        </>
    )
}