import infoLogo from '../assets/images/info.svg'

export default function SummaryStatBox(props){
    const featureLogoStyle = props.imageStyle ? props.imageStyle : {
        width: '40px',
        height: '40px',
        flexShrink: '0'
    }

    const statContainerStyle = {
        bordeRadius: '8px',
        border: '1px solid #CBCBCB',
        background: '#FFF',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
        width: '317.75px',
        padding: '22px'        
    }

    const iconContainerStyle = props.imageStyle ? {
        display: 'flex',
        width: '40px',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: props.imageBackgroundColor ? props.imageBackgroundColor : 'white',
        borderRadius: '50%',
    } : {
        width: '40px',
        height: '40px',
    }

    const statNumberStyle = {
        marginBottom: '-15px',
        color: '#000',
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '54px',
        fontStyle: 'normal',
        fontWeight: '400',
    }


    return(
        <div style={statContainerStyle}>
            <div style={iconContainerStyle}>
                <img src={props.image.source} alt={props.image.alt} style={featureLogoStyle} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', verticalAlign: 'center', alignItems: 'center'}}>
                <p style={{marginBottom: '0px', marginTop: '18.5px'}}>{props.name}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', verticalAlign: 'center', alignItems: 'center'}}>
                <p style={statNumberStyle}>{props.number}</p>
                <p style={{...statNumberStyle, fontSize: props.supplementaryFontSize}}>{props.supplementaryText}</p>
            </div>

        </div>
    )
}