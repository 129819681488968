import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function DateOfBirth() {
  const [birthdate, setBirthdate] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { state } = useLocation();
  const {phoneNumber, firstName, lastName, gender} = state;

  const handleBackArrow = () => {
    navigate('/gender', {state: {phoneNumber, firstName, lastName}});
  };

  const calculateAge = (birthDate) => {
    setError('')
    let currentDate = new Date();
    let inputDate = new Date(birthDate);
    let age = currentDate.getFullYear() - inputDate.getFullYear();
  
    if (
      currentDate.getMonth() < inputDate.getMonth() ||
      (currentDate.getMonth() === inputDate.getMonth() &&
        currentDate.getDate() < inputDate.getDate())
    ) {
      age--;
    }
  
    return age;
  };  

  const handleSubmit = () => {
    if (new Date(birthdate) > new Date()) {
      setError("Please select a valid date of birth.");
    } else if (calculateAge(birthdate) < 13) {
      setError("You must be at least 13 years old to join.");
    } else {
      navigate('/Ethnicity', { state: {phoneNumber, firstName, lastName, gender, dob: birthdate} });
    }

  };
  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className="title-verification">What's your date of birth?</h2>

      <div className="date-input-container" style = {{marginBottom: '200px'}}>
        <input
          type="date"
          className="date-input-field"
          value={birthdate}
          onChange={(e) => setBirthdate(e.target.value)}
        />
        <svg className="date-input-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <mask id="mask0_3_2405" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_3_2405)">
            <path d="M12.0001 15.05L6.3501 9.37495L7.4001 8.32495L12.0001 12.925L16.6001 8.32495L17.6501 9.37495L12.0001 15.05Z" fill="#1C1B1F"/>
          </g>
        </svg>
      </div>

      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}
      <button
        className = {`button ${birthdate.length === 0 ? 'grey-background' : 'red-background'}`}
        disabled={birthdate.length === 0}
        onClick={handleSubmit}
      >
        Next
      </button>
    </div>
  );
}

export default DateOfBirth;