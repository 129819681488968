import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function formatPhoneNumber(inputValue) {
  const cleaned = inputValue.replace(/\D/g, '');
  const match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  
  if (match) {
    return match.slice(1, 4).filter(Boolean).join('-');
  } else {
    return '';
  }
}

function AddNumber() {
    const [paymentPhoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();
  
    const { state } = useLocation();
    const { phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType} = state;
    const handleBackArrow = () => {
      navigate('/payment', {state: {phoneNumber: phoneNumber, firstName: firstName, lastName: lastName, gender: gender, dob:dob, ethnicity: ethnicity, selectedState: selectedState, interests:interests, email: email, image}});
    };
  
    const handleSubmit = () => {
      navigate('/TermsAndConditions', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType, paymentInfo: paymentPhoneNumber}} );
    };
  
    return (
      <div className="signup-container">
        <div className="arrow-button">
            <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
        </div>
        <h2 className='title-verification'>Phone number connected to your Venmo account:</h2>
  
        <input 
          type="tel"
          placeholder="123-456-7890"
          className="input-field"
          value={paymentPhoneNumber}
          onChange={(e) => {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            setPhoneNumber(formattedPhoneNumber);
          }}
        />
        <button
          style={{marginTop: '150px'}}
          className={`button ${paymentPhoneNumber.length !== 12 ? 'grey-background' : 'red-background'}`}
          disabled={paymentPhoneNumber.length !== 12}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    );
  }
  
  export default AddNumber;