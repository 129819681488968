import { useLocation} from 'react-router-dom';
import '../App.css';
import thankYouImage from '../assets/images/thankyoupage.png'
import landingPage from '../assets/images/AmbassCoLandingPageWallpaper.png'
import logo from '../assets/images/blacknoback.png'
import logoText from '../assets/images/AmbasscoTextLogo.png'
import { useRef, useState} from 'react';


function ThankYouPage() {
    const {state} = useLocation();
    const {text} = state;
    return (
        <div>
            <div className="background-image"></div>
            <div style={{
                justifyContent: 'flex-start',
                overflow: 'hidden',
                position: 'relative',
                alignItems: 'center'
            }} className='signup-container'>
                <div style={{marginTop: '70px'}}>
                    <img width='320.71' height='auto' src={thankYouImage} alt="Smily Face"></img>
                </div>
                <h2 style={{lineHeight:'63px', marginTop: '70px', fontWeight: '700',fontSize: '52.5px', marginBottom: '0px'}}>thank you</h2>
                <h2 style={{lineHeight:'63px', fontWeight: '382',fontSize: '38px'}}>{text}</h2>
                <img src={logo} width='119.8px' height='auto'></img>
                <img style={{marginTop: '-115px'}} src={logoText} width='200px' height='auto'></img>

            </div>
        </div>
    );
}

export default ThankYouPage;

