// import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import CryptoJS from "crypto-js";
import { Button, Form } from "react-bootstrap";

const SECRET = "as93ekdxmjsh73";

export const encrypttoHEX= (input) => {
  var b64 = CryptoJS.AES.encrypt(input, SECRET).toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}

export const decryptfromHEX = (input) => {
  var reb64 = CryptoJS.enc.Hex.parse(input);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, SECRET);
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
}
const EncodeDecode = () => {
  const [value, setValue] = useState("");
  const [encrypt, setEncrypt] = useState("");
  const [decrypt, setDecrypt] = useState("");
 
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const encryptHandler = () => {
    
    setEncrypt(
      // CryptoJS.AES.encrypt((JSON.stringify(value)), "my-secret-key").toString(CryptoJS.enc.Hex)
      encrypttoHEX(value)
    );
  };
  const decryptHandler = () => {
    
    setDecrypt(
      // JSON.parse(
      //   CryptoJS.AES.decrypt((encrypt), "my-secret-key").toString(
      //     CryptoJS.enc.Utf8
      //   )
      // )
      decryptfromHEX(value)
    );
  };
  return (
    <div className="d-flex flex-column align-items-center">
      <Form onSubmit={(e) => e.preventDefault()} className="w-25">
        <Form.Group className="mb-3">
          <Form.Label title="">Encrypt</Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            onChange={handleChange}
          />
        </Form.Group>
        <p>Encrypted text: {encrypt}</p>
        <p>Decrypted text: {decrypt}</p>
        <Button variant="primary" color="danger" onClick={encryptHandler}>
          Encrypt
        </Button>

        <Button
          className="m-3"
          variant="primary"
          color="danger"
          onClick={decryptHandler}
        >
          Decrypt
        </Button>
      </Form>
    </div>
  );
};

export default EncodeDecode;