import { components } from "react-select";
import { default as ReactSelect } from "react-select";

export default function FilterDropDown(prop) {


    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
      };

    const filterDropDownStyle = {
        control: (styles) => 
            ({...styles,
            border: '0.5px solid #CBCBCB',
            background: '#FBFBFB',
            width: "250px"})
    }

    const handleOnFilterChange = (value) => {
        prop.setFilter(
            value.map((val, index) => ({
                filterType: prop.filterType,
                filterValue: val.value
            } ))
        )
    }

    return (
        <ReactSelect
            styles={filterDropDownStyle}
            options={prop.options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option
            }}
            allowSelectAll={true}
            placeholder={prop.name}
            onChange={value => handleOnFilterChange(value)}
        />
    )
}