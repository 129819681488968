import SummaryStatBox from "./SummaryStatBox";

import sentimentIcon from '../assets/images/avgsentimentreporticon.svg';
import superLikeIcon from '../assets/images/superlikereporticon.svg';
import shareIcon from '../assets/images/sharereporticon.png';
//import timeSpentIcon from '../assets/images/timespentreporticon.svg';
import commentIcon from '../assets/images/commentreporticon.svg';
import linkClickIcon from '../assets/images/linkclickreporticon.png';
import AssetSentimentChart from "./AssetSentimentChart";
import AmbassadorReactionBox from "./AmbassadorReactionBox";
import CommentContainer from "./CommentContainer";
import { useEffect } from "react";


export default function AssetSummary(prop){
    useEffect(() => {
        console.log(prop.reactions.superLike)
    }, []
    )
    const AssetContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        fontFamily: 'Acumin-RPro',
        marginLeft: '178px',
        marginBottom: '100px'
    };

    const TitleStyle = {
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '48px',
        height: '30px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal'
    }

    const subTitleStyle = {
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: 'normal',
        height: '50px'
        
    }

    const sectionTitleStyle = {
        fontSize: '28px',
        fontWeight: '400px',
        marginTop: '100px'
    }

    const statBoxLayoutStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '30px',
        padding: '0px',
        marginBottom: '30px'
    }

    const sentimentProps = {
        name: 'Average Sentiment',
        image: {
            alt: "Sentiment Icon",
            source: sentimentIcon
        },
        number:prop.sentimentCount,
        supplementaryText: "/5",
        supplementaryFontSize: '54px'
    }

    const LinkClickProps = {
        name: 'Link Clicks Received',
        image: {
            alt: "Link Click Icon",
            source: linkClickIcon
        },
        imageStyle: {
            width: '24px',
            height: '24px',
            flexShrink: '0'
        },
        imageBackgroundColor: "#D9D9D9",
        number:prop.linkClickCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }

    const superLikeProps = {
        name: 'Superfans Received',
        image: {
            alt: "Superlike Icon",
            source: superLikeIcon
        },
        number:prop.superLikeCount,
        supplementaryText: "",
        supplementaryFontSize: '54px'
    }

    // const assetTimeProps = {
    //     name: "Overall time spent on assets",
    //     image: {
    //         alt: "Asset Time Icon",
    //         source: timeSpentIcon
    //     },
    //     number:prop.assetTimeCount,
    //     supplementaryText: "seconds",
    //     supplementaryFontSize: '36px'
    // }

    const commentProps = {
        name: "Comments Received",
        image: {
            alt: "Comment Logo",
            source: commentIcon
        },
        number:prop.commentCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }

    const shareProps = {
        name: "Shares",
        image: {
            alt: "Share Logo",
            source: shareIcon
        },
        imageStyle: {
            width: '24px',
            height: '24px',
            flexShrink: '0',
            marginRight: '1.5px',
            marginTop: '2px'
        },
        imageBackgroundColor: 'black',
        number:prop.shareCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }


    return(
        <div style={AssetContainerStyle} id={"Asset" + prop.id}>
            <h2 style={TitleStyle}>{prop.name}</h2>
            <p style={sectionTitleStyle}>Asset Summary Stats</p>
            <ul style={statBoxLayoutStyle}>
                <SummaryStatBox {...sentimentProps}></SummaryStatBox>
                <SummaryStatBox {...LinkClickProps}></SummaryStatBox>
                <SummaryStatBox {...superLikeProps}></SummaryStatBox>
            </ul>
            <ul style={statBoxLayoutStyle}>
                <SummaryStatBox {...commentProps}></SummaryStatBox>
                <SummaryStatBox {...shareProps}></SummaryStatBox>
            </ul>
            <p style={{...sectionTitleStyle, marginTop: '100px'}}>Average Sentiment Chart</p>
            <AssetSentimentChart values={prop.sentimentDistribution}></AssetSentimentChart>
            <p style={{...sectionTitleStyle, marginTop: '69px'}}>Ambassador Reactions</p>
            <ul style={statBoxLayoutStyle}>
                <AmbassadorReactionBox name="Superfans" reactions={prop.reactions.superLike}></AmbassadorReactionBox>
                <AmbassadorReactionBox name="Shares" reactions={prop.reactions.share}></AmbassadorReactionBox>
                <AmbassadorReactionBox name="Link Clicks" reactions={prop.reactions.linkClick}></AmbassadorReactionBox>
            </ul>
            <p style={{...sectionTitleStyle, marginTop: '100px'}}>Comments</p>
            <CommentContainer comments={prop.comments}></CommentContainer>
        </div>
    )

}