import AmbassadorProfileBox from "./AmbassadorProfileBox"
import profilePictureMock from "../assets/images/profilepicturemock.png"

export default function AmbassadorReactionBox(props){
    const reactionContainerStyle = {
        borderRadius: '8px',
        border: '1px solid #CBCBCB',
        background: '#FFF',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
        maxHeight: '694px',
        flex: '1 0 0'
    }


    const graphTitleStyle =  {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        marginTop: '25px'
    }

    const profileLayoutStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        padding: '17px',
        maxHeight: '500px',
        overflow: 'auto',
        marginTop: "70px",
    }

    const titleContainerStyle = {
        marginLeft: '31px',
        position: 'absolute',
        padding: '25px, 25px'
    }

    const reactionMap = (reaction) => 
    <AmbassadorProfileBox {...reaction}>
    </AmbassadorProfileBox>

    const content = (
        props.reactions.length ?
            <div style={profileLayoutStyle}>
                {props.reactions.map(reactionMap)}
            </div>
        : <p style={{
        padding: '17px',
        marginTop: "70px",
        textAlign: "center"}}>No Data</p>
    )
            
    

    
    return(
    
        <div style={reactionContainerStyle}>
            <div style={titleContainerStyle}>
                <p style={graphTitleStyle}>{props.name}</p>
            </div>
            <div>
                {content}
            </div>

        </div>
        
    )
}