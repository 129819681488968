import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function SelectInterests() {
  const [interests, setInterests] = useState({
    beauty: false,
    sports: false,
    lifestyle: false,
    fashion: false,
    skincare: false,
    foodAndBev: false,
    fitness: false,
    gamingEsports: false,
  });

  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState} = state;

  const handleBackArrow = () => {
    navigate('/SelectState', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity}});
  };

  const toggleInterest = (interest) => {
    setInterests({ ...interests, [interest]: !interests[interest] });
  };

  let atLeastOneSelected = Object.values(interests).some((selected) => selected);

  const handleSubmit = () => {
    
    if (atLeastOneSelected) {
      // Create an array of strings based on the selected interests in the order of beauty, sports, lifestyle, fashion, ...
      const selectedInterestsArray = Object.keys(interests).filter((interest) => interests[interest]);
      navigate('/emailaddress', { state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests: selectedInterestsArray} });
    }
  };

  return (
    <div className='signup-container'>
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className="title-verification">
        Select your interests
      </h2>
      <p className='interest-description-text'>
        You can select as many interests as you’d like! <br/>
        Based on your interests, we’ll show you content for you to give feedback on. 
      </p>
      <div className='interests-button-container'>
        <div className='columns-interests'>
          <button
            className={`select-interest-button ${interests.beauty ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('beauty')}
          >
            Beauty
          </button>
          <button
            className={`select-interest-button ${interests.sports ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('sports')}
          >
            Sports
          </button>
          <button
            className={`select-interest-button ${interests.lifestyle ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('lifestyle')}
          >
            Lifestyle
          </button>
          <button
            className={`select-interest-button ${interests.fashion ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('fashion')}
          >
            Fashion
          </button>
          <button
            className={`select-interest-button ${interests.skincare ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('skincare')}
          >
            Skincare
          </button>
          <button
            className={`select-interest-button ${interests.foodAndBev ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('foodAndBev')}
          >
            Food & Bev
          </button>
          <button
            className={`select-interest-button ${interests.fitness ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('fitness')}
          >
            Fitness
          </button>
          <button
            className={`select-interest-button ${interests.gamingEsports ? 'pink-background' : 'normal-background'}`}
            onClick={() => toggleInterest('gamingEsports')}
          >
            Gaming/e-sports
          </button>
        </div>
      </div>
      <button
        style={{marginTop:'110px'}}
        className={`button ${atLeastOneSelected ? 'red-background' : 'grey-background'}`}
        onClick={handleSubmit}
      >
        Next
      </button>
    </div>
  );
}

export default SelectInterests;