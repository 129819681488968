import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/blacknoback.png';  // Import logo like in the previous code

const profileContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
    backgroundColor: '#F7E0D9',
    position: 'relative',
    fontFamily: 'Acumin-RPro',
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 0',
    borderBottom: '2px solid #E63946',
};

const inputStyle = {
    margin: '10px 0',
    padding: '10px',
    width: '300px',
    border: '1px solid #E63946',
    borderRadius: '23px',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Acumin-RPro',
};

const labelStyle = {
    fontWeight: 'bold',
    color: '#E63946',
    display: 'block',  // This will make labels appear above input fields
    margin: '10px 0',
};

const logoStyle = {
    width: '60px',
};

const backToFeedStyle = {
    textDecoration: 'none',
    margin: '20px 0',
    fontFamily: 'Acumin-RPro',
    color: '#E63946',
};

function Profile() {
    return (
        <div style={profileContainerStyle}>
            <div style={headerStyle}>
                <h2>Profile</h2>
                <img src={logo} alt="Logo" style={logoStyle} />
            </div>
            
            <div>
                <label style={labelStyle} htmlFor="firstName">First Name:</label>
                <input style={inputStyle} type="text" id="firstName" />
            </div>
            <div>
                <label style={labelStyle} htmlFor="lastName">Last Name:</label>
                <input style={inputStyle} type="text" id="lastName" />
            </div>
            <div>
                <label style={labelStyle} htmlFor="phone">Phone Number:</label>
                <input style={inputStyle} type="tel" id="phone" />
            </div>
            <div>
                <label style={labelStyle} htmlFor="email">Email:</label>
                <input style={inputStyle} type="email" id="email" />
            </div>
            <div>
                <label style={labelStyle} htmlFor="gender">Gender:</label>
                <select style={inputStyle} id="gender">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div>
                <label style={labelStyle} htmlFor="dob">Date of Birth:</label>
                <input style={inputStyle} type="date" id="dob" />
            </div>
            <div>
                <label style={labelStyle}>Interests:</label>
                <div>
                    <input type="checkbox" id="clothing" name="interests" value="clothing" />
                    <label htmlFor="clothing">Clothing</label>
                </div>
                <div>
                    <input type="checkbox" id="food" name="interests" value="food" />
                    <label htmlFor="food">Food</label>
                </div>
                <div>
                    <input type="checkbox" id="sports" name="interests" value="sports" />
                    <label htmlFor="sports">Sports</label>
                </div>
                <div>
                    <input type="checkbox" id="style" name="interests" value="style" />
                    <label htmlFor="style">Style</label>
                </div>
            </div>
            <Link to="/feed" style={backToFeedStyle}>Back to Feed</Link>
        </div>
    );
}

export default Profile;
