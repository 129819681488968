import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import paypal from '../assets/images/Paypal.svg';
import venmo from '../assets/images/venmo.svg';
import '../App.css'

function Payment() {
  const [isPaypalHovered, setIsPaypalHovered] = useState(false);
  const [isVenmoHovered, setIsVenmoHovered] = useState(false);
  const [paymentType, setPaymentType] = useState('')
  const navigate = useNavigate();

  const { state } = useLocation();
  const {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image} = state;


  const handleBackArrow = () => {
    navigate('/uploadprofilepicture', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email}});
  };

  const handlePaypalClick = () => {
    setPaymentType('paypal')
    //sendPaymentInfo("paypal");
  };
  

  const handleVenmoClick = () => {
    setPaymentType('venmo')
    //sendPaymentInfo("venmo");
  };

  useEffect(() => {
    if (paymentType && paymentType === 'paypal') {
      navigate('/paymentpaypal', { state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType: paymentType} });
    }
    if (paymentType && paymentType === 'venmo') {
      navigate('/paymentvenmo', { state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType: paymentType} });
    }
  }, [paymentType, navigate, phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image]);

  return (
    <div className="signup2-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Back" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className='title-verification'>Select Payment Method</h2>
      <p className='interest-description-text'>This is how we'll pay you after completing projects</p>
      <div className='payment-button-container'>
        <button className={`payment-button ${isPaypalHovered ? 'payment-button-hovered' : ''}`} onClick={handlePaypalClick} onMouseEnter={() => setIsPaypalHovered(true)} onMouseLeave={() => setIsPaypalHovered(false)}>
          <img src={paypal} alt="PaypalLogo" className='paypal-venmo-logo' />
          <span style={{ marginRight: '60%' }}>Paypal</span> {}
        </button>
        <button className={`payment-button ${isVenmoHovered ? 'payment-button-hovered' : ''}`} onClick={handleVenmoClick} onMouseEnter={() => setIsVenmoHovered(true)} onMouseLeave={() => setIsVenmoHovered(false)}>
          <img src={venmo} alt="VenmoLogo" className='paypal-venmo-logo' />
          <span style={{ marginRight: '60%' }}>Venmo</span> {}
        </button>
      </div>
    </div>
  );
}

export default Payment;
