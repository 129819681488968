import SummaryStatBox from "./SummaryStatBox";
import AmbassadorGraph from "./AmbassadorGraph";

import sentimentIcon from '../assets/images/avgsentimentreporticon.svg';
import superLikeIcon from '../assets/images/superlikereporticon.svg';
import shareIcon from '../assets/images/sharereporticon.png';
import commentIcon from '../assets/images/commentreporticon.svg';
import linkClickIcon from '../assets/images/linkclickreporticon.png';
import ambassadorCountIcon from '../assets/images/ambassadorcountreporticon.png';


export default function SessionOverview(prop){
    const AssetStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        fontFamily: 'Acumin-RPro',
        marginLeft: '178px'
    };

    const TitleStyle = {
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '48px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal'
    }

    const subTitleStyle = {
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: 'normal',
        height: '50px'
        
    }

    const sectionTitleStyle = {
        fontSize: '28px',
        fontWeight: '400px',
    }

    const statBoxLayoutStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '30px',
        padding: '0px',
        marginBottom: '30px'
    }

    const sentimentProps = {
        name: 'Average Sentiment',
        image: {
            alt: "Sentiment Icon",
            source: sentimentIcon
        },
        number:prop.sentimentCount,
        supplementaryText: "/5",
        supplementaryFontSize: '54px'
    }

    const LinkClickProps = {
        name: 'Link Clicks Received',
        image: {
            alt: "Link Click Icon",
            source: linkClickIcon
        },
        imageStyle: {
            width: '24px',
            height: '24px',
            flexShrink: '0'
        },
        imageBackgroundColor: "#D9D9D9",
        number:prop.linkClickCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }

    const superLikeProps = {
        name: 'Superfans Received',
        image: {
            alt: "Superlike Icon",
            source: superLikeIcon
        },
        number:prop.superLikeCount,
        supplementaryText: "",
        supplementaryFontSize: '54px'
    }

    // const assetTimeProps = {
    //     name: "Overall time spent on assets",
    //     image: {
    //         alt: "Asset Time Icon",
    //         source: timeSpentIcon
    //     },
    //     number:prop.assetTimeCount,
    //     supplementaryText: "seconds",
    //     supplementaryFontSize: '36px'
    // }

    const commentProps = {
        name: "Comments Received",
        image: {
            alt: "Comment Logo",
            source: commentIcon
        },
        number:prop.commentCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }

    const shareProps = {
        name: "Shares",
        image: {
            alt: "Share Logo",
            source: shareIcon
        },
        imageStyle: {
            width: '24px',
            height: '24px',
            flexShrink: '0',
            marginRight: '1.5px',
            marginTop: '2px'
        },
        imageBackgroundColor: 'black',
        number:prop.shareCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }

    const ambassadorCountProps = {
        name: "Total Number of Ambassadors",
        image: {
            alt: "Ambassador Count Logo",
            source: ambassadorCountIcon
        },
        imageStyle: {
            width: '50px',
            height: '50px',
            flexShrink: '0',
        },
        imageBackgroundColor: 'black',
        number:prop.ambassadorCount,
        supplementaryText: "",
        supplementaryFontSize: "54px"
    }

    return(
        <div style={AssetStyle} id="overview">
            <h2 style={TitleStyle}>Session Overview</h2>
            <p style={sectionTitleStyle}>Session Summary Stats</p>
            <ul style={statBoxLayoutStyle}>
                <SummaryStatBox {...sentimentProps}></SummaryStatBox>
                <SummaryStatBox {...LinkClickProps}></SummaryStatBox>
                <SummaryStatBox {...superLikeProps}></SummaryStatBox>
            </ul>
            <ul style={statBoxLayoutStyle}>
                <SummaryStatBox {...commentProps}></SummaryStatBox>
                <SummaryStatBox {...shareProps}></SummaryStatBox>
            </ul>
            <p style={{...sectionTitleStyle, marginTop: '100px'}}>Session Demographics</p>
            <div style={statBoxLayoutStyle}>
                <SummaryStatBox {...ambassadorCountProps}></SummaryStatBox>
            </div>
            <div style={statBoxLayoutStyle}>
                <AmbassadorGraph name="Age" chartValues={{...prop.ageDistribution}} color="#DB4225" isBar={true}></AmbassadorGraph>
                <AmbassadorGraph name="Location" chartValues={{...prop.locationDistribution}} color="#BFC0DF" isBar={true}></AmbassadorGraph>
            </div>
            <div style={statBoxLayoutStyle}>
                <AmbassadorGraph name="Gender" chartValues={{...prop.genderDistribution}} isBar={false}></AmbassadorGraph>
                <AmbassadorGraph name="Ethnicity" chartValues={{...prop.ethnicityDistribution}} isBar={false}></AmbassadorGraph>
            </div>

        </div>
    )

}