
import React, { useEffect } from 'react'
import { Navigate, Route } from 'react-router-dom'

function PrivateRoute({children, token}) {

  // Add your own authentication on the below line.

  useEffect(() => {
    console.log(children)
  }, [token, children])
  return (
    token ? children : <Navigate to="/login" />

  );
}

export default PrivateRoute