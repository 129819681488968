import { Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
  );

export default function AssetSentimentChart(props) {
    const statContainerStyle = {
        width: '1361px',
        height: '372px',
        borderRadius: '8px',
        border: '1px solid #CBCBCB',
        background: '#FFF',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)'
    }

    const graphBodyStyle = {
        marginLeft: '26px',
        marginTop: '20px',
        height: '300px',
        position: 'relative'        
    }
    const options = {
        responsive: true,
        maintainAspectRation: false,
        aspectRatio: 4,
        plugins: {
            legend: {
                display: false,
            }
        }
    }
    const data = {
        labels: ["Hate (1)", "Don’t Like (2)", "Indifferent (3)",  "Like (4)", "Love (5)"],
        datasets: [
          {
            data: props.values,
            backgroundColor: "#EFC582",
          },
        ],
    };
    return (
        <div style={statContainerStyle}>
            <div style={graphBodyStyle}>
                <Bar options={options} data={data} width='1300px'></Bar>
            </div>
        </div>
    )
}